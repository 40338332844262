
function getReverse(x) {
    let reverseSecondPart = 0;

    for (let i = 0; i < 32; i++) {
        let temp = x >> (31 - i);
        temp &= 1;
        temp = temp << i;
        reverseSecondPart |= temp;
    }

    return reverseSecondPart;
}
  
function getFullBinary(string, count) {
    let str2 = string;
    while (str2.length < count) {
        str2 = "0" + str2;
    }
    return str2;
}
  
function DecodeErc(inputErc) {
if (inputErc.length !== 16) {
    return "Invalid number of characters.";
} else if (inputErc.length === 16) {
    // Splits the string into 8 character strings.
    let firstPart = inputErc.substring(0, 8);
    let secondPart = inputErc.substring(8, 16);

    // Treating the strings returned as HEX, converts them to an int.
    let fpInt = parseInt(firstPart, 16);
    let spInt = parseInt(secondPart, 16);

    let reverseSecondPart = getReverse(spInt);

    let xor = fpInt ^ reverseSecondPart;
    let ret = (xor - 0xE010A11) + Math.pow(2, 32);

    // Formats the return as hex
    let xd = ret.toString(16);

    let last = getFullBinary(xd, 8);
    return last.toUpperCase();
} else {
    return "error";
}
}
  
// Exporting functions as named exports
export { getReverse, getFullBinary, DecodeErc };