import React from 'react';

const JsonLdComponent = () => {
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Free Toyota ERC Headunit Unlock Tool",
    "url": "https://www.ercunlock.com",
    "description": "Our tool provides a quick and easy way to generate ERC unlock codes for free, helping you restore full functionality to your Japanese Toyota head unit without the need for costly dealership visits.",
    "mainEntity": [
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is ERC?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Emergency Rescue Code (ERC) is a unique, vehicle-specific code used to unlock the head unit of certain Japanese Toyota vehicles. This code is necessary when the head unit becomes locked, which can occur due to security features activated after events like a battery disconnection or incorrect entry attempts. Without the correct ERC, the head unit remains inoperable, preventing access to important functions such as navigation, audio, and other in-car systems. ERCs ensure that only authorized users can regain control of the head unit, thus enhancing the security of the vehicle’s infotainment system."
            }
          },
          {
            "@type": "Question",
            "name": "How do I find my 16 digit code?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "To get the ERC Unlock Code, first you need to retrieve the ERC Serial Number from your car's head unit by following these steps:\n1. Press and Hold the Main Button: On your navigation player, press and hold the main button.\n2. Turn Parking Lights On/Off: Switch your parking lights on and off 3 to 4 times until a new screen appears on the head unit.\n3. Press the Active Buttons: Follow the prompts on the screen and press only the active buttons. This will lead you to a screen displaying the 16-digit ERC serial number. Examples of ERC serial numbers include 12345ABC6789DEFG and 9F8E7D6C5B4A3210.\n4. If these steps do not work for your specific model, you may need to research the procedure for your particular vehicle or consult additional resources."
            }
          }
        ]
      },
      {
        "@type": "SoftwareApplication",
        "name": "Toyota ERC Decoder",
        "operatingSystem": "All",
        "applicationCategory": "Utility",
        "description": "Free Toyota ERC Headunit Unlock Tool helps you decode ERC codes quickly and easily.",
        "offers": {
          "@type": "Offer",
          "price": "0",
          "priceCurrency": "USD"
        }
      }
    ]
  };

  return (
    <script type="application/ld+json">
      {JSON.stringify(jsonLdData)}
    </script>
  );
};

export default JsonLdComponent;
